// import { Button } from "bootstrap";
import * as React from "react";
import "./routes.css";

const testimonialsTitles = {
  color: "#B46000",
  padding: ".5rem",
  marginLeft: "1rem",
  paddingTop: "1rem",
};

const testimonialsBackground = {
  backgroundColor: "#D0C88E",
};
const testimonialsContentBackground = {
  backgroundColor: "#E9E4D2",
};
const testimonialsText = {
  padding: ".5rem",
  marginLeft: "1rem",
};
const testimonialsPad = {
  padding: "1.5rem",
};
// const aboutFinalP = {
//   padding: ".5rem",
//   marginLeft: "1rem",
//   marginBottom: "0rem",
// };

function Testimonials() {
  return (
    <>
      <div style={testimonialsBackground}>
        <div className="container">
          <div style={testimonialsContentBackground}>
            <h1 style={testimonialsTitles}>Testimonials</h1>

            <h5 style={testimonialsText}>
              <strong>
                Names are withheld to protect privacy unless requested
                otherwise.
              </strong>{" "}
              <br />
              <small>
                We'd love to hear from you. Send us your comments, suggestions,
                testimonials, thoughts. And let us know if you are interested in
                hearing about upcoming classes or offerings.
              </small>
            </h5>
            <p style={testimonialsText}>
              Please send comments and questions to{" "}
              <a
                href="mailto:arwatkins54@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                arwatkins54@gmail.com
              </a>
            </p>

            <div className="container">
              <div
                className="accordion"
                id="accordionExample"
                style={testimonialsPad}
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <strong>1. DD (60's Female)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Hi Ann - I'm off tomorrow, but before leaving want you
                        to know that I have happily reduced my tylenol to 4x
                        650/day. If it wasn't for the 15 hr plane ride I'd be
                        reducing it some more. Your last session was, I believe,
                        a break through and I look forward to setting up a
                        series of appts in March. I'll be back in touch after
                        India and before we head to CA. I am thrilled to be
                        feeling so much better on the eve of this exciting trip.
                        Thank you. X
                      </p>
                      <p>
                        (DD is now taking only 3 tylenol a day, occasionally has
                        been able to take only two and is anticipating reducing
                        the dose size of one of the Tylenol anticipating
                        eventually not needing regular painkillers at all.)
                      </p>
                      <p>
                        I thought you'd like to know that Friday’s appointment
                        was a real breakthrough. My neck is STILL feeling much
                        better!!! I have never been able to hold on to the
                        benefits of any one's work for this long. I have also
                        slept significantly better the last three nights -hurrah
                        and MANY THANKS.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <strong>2. JB (40's)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      "Thank you, thank you, thank you."
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <strong>3. DC (40's)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      "I really didn't understand what Ann was doing the first
                      few times I went to her. I never had massage like this
                      before, I had the run of the mill massage and although
                      that was nice, it didn't address my pain in any lasting
                      way. Ann explained what she was doing as she worked and I
                      began to understand it and I began to have relief of my
                      decades of chronic pain. I now have no more pain in my
                      lower back and leg. None. I still visit her now and then
                      because Ann is always studying and coming up with new
                      solutions and I like to take advantage of her knowledge so
                      I can feel great."
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <strong>4. AW (40's)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Dear Randy, I wanted to thank you for all of the love and
                      attention you have given my body in this past year. What
                      you do is amazing and being able to come to you for a
                      session has been such an important and stabilizing force
                      in my life. I wish that only the best comes to you in the
                      future. I will be sure to look you up any time I’m in the
                      area.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <strong>5. SS (40's)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Ann, Thanks again. I feel great, still.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      <strong>6. CB (50's)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      New Harmony massage offers a wide range of therapies to
                      meet individual needs. I am healthier and stronger (plus I
                      feel exceptionally better) because of my time here. I
                      recommend New Harmony Massage Therapy to everyone.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSeven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      <strong>6. LJ (60's)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Ann is always generous with her time and amazing at
                      sensing what needs repair and reworking. I can’t say
                      enough about her care and can highly recommend her.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingEight">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      <strong>8. JF (40's)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      I have had chronic posture issues and when Ann works on me
                      I always come out breathing easier and standing
                      straighter.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingNine">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      <strong>9. MJ (50's)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingNine"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Hi Ann, Thank you so much for your good work yesterday...I
                      have experienced relief and we have found the nexus of the
                      30 year old issues...I am grateful and look forward to
                      more therapy...
                    </div>
                  </div>
                </div>

                <h5 style={testimonialsText}>
                  <strong>Movement for Body Mind Spirit™ Testimonials</strong>{" "}
                  <br />
                  <small>
                    <a href="/classes" target="_blank" rel="noreferrer">
                      Class Information
                    </a>
                    {/* <a href="http://www.newharmonymassage.com/classes.htm#mbms">Class Information</a> */}
                  </small>
                </h5>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      <strong>10. Lana Baumgartner (25)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseTen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Great! I loved experiencing how unique your teaching style
                      is...your facilitating inspired me to create new ways of
                      speaking, thinking, moving-a new way to express! I found a
                      whole new dimension through you! I felt an expansion of my
                      own creativity by opening my eyes to the vision you
                      inspired. Love and Dance!
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingEleven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEleven"
                      aria-expanded="false"
                      aria-controls="collapseEleven"
                    >
                      <strong>11. Susan (40's)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseEleven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEleven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>Movement for Body Mind Spirit™</strong> is fun,
                      freedom of movement and self-expression that reminds me we
                      were all children once. It also reminds me we have a
                      connection to the earth, and others around us. See you
                      soon!
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwelve">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwelve"
                      aria-expanded="false"
                      aria-controls="collapseTwelve"
                    >
                      <strong>12. Shirley (70's)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseTwelve"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwelve"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Ann always finds a way to make every class invigorating
                      and a whole lot of fun in the process. I've been coming
                      since the beginning classes and no class is ever quite the
                      same, but I always come away feeling more limber and in
                      good spirits.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThirteen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThirteen"
                      aria-expanded="false"
                      aria-controls="collapseThirteen"
                    >
                      <strong>13. Mary G (40's)</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseThirteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThirteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        I keep coming up with little phrases to describe my{" "}
                        <strong>Movement for Body Mind Spirit™</strong>{" "}
                        experience: --I feel like I've stretched my heart and
                        soul as well as my body. And I've connected my heart and
                        soul to my body... --After class, I feel more creative,
                        energetic, and in tune with the natural world --It gives
                        me a different way of moving through my feelings and
                        emotions --It connects me with rhythms in my own body,
                        and with the rhythms of the rest of the group. It feels
                        like we've developed a community without speaking words
                        --It gives me a place to explore and experiment.
                      </p>
                      <p>
                        Here's a great quote about dance as a spiritual practice
                        from a book I've been reading: <br />
                        "The ongoing effort to dance, to give gesture to what we
                        feel and experience, is ultimately healing because, as
                        riverbeds are continually shaped by water that moves
                        through them, living beings are continually shaped by
                        the feelings and experiences that move through them. If
                        there is no water moving through, the riverbed dries up
                        and crumbles. Likewise, if there is no feeling moving
                        through the body, the being at the center of that body
                        will crumble in truth, much of our inner sickness comes
                        from the buildup and pressure of all that is kept in.
                        The ongoing act of releasing that inner buildup is what
                        spiritual practices call embodiment. Embodiment is
                        allowing our heart, mind, and body to exist as one
                        miraculous skin." <br />
                        <strong>
                          {" "}
                          The Book of Awakening - Page: 207, By: Mark Nepo
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Testimonials;
