// import { Button } from "bootstrap";
import * as React from "react";
import "./routes.css";

const boilerBackground = {
  backgroundColor: "#D0C88E",
};
const boilerContentBackground = {
  backgroundColor: "#E9E4D2",
};
const boilerTitles = {
  color: "#B46000",
  padding: ".5rem",
  marginLeft: "1rem",
  paddingTop: "1rem",
};
const boilerText = {
  padding: ".5rem",
  marginLeft: "1rem",
};

function Blog() {
  return (
    <>
      <div style={boilerBackground}>
        <div className="container">
          <div style={boilerContentBackground}>
            <h1 style={boilerTitles}>Blog Posts</h1>

            <h5 style={boilerText}>
              <strong>
                {" "}
                What's Happening at New Harmony Therapeutic Massage and Somatic
                Bodywork?
              </strong>{" "}
              <br />
              <small>
                To provide healing and improve the quality of life on the planet
                one person at a time
              </small>
            </h5>

            <h1 style={boilerTitles}>
              Conscious Anatomy™ Bones Planes and Directions
            </h1>
            {/* <small style={boilerText}> 1/12/2022: May 5, 12, 19, 26 2022 10AM-2:30PM 4 Thursdays</small>{" "} */}
            <div style={boilerText}>
              <p>
                I envision a world where the benefits of Conscious Anatomy™ are
                universally applied to bring about greater harmony to the whole
                world one person or group at a time.
              </p>
              <p>
                Discover how to move with ease in your body. Dissolve issues in
                your tissues by bringing the unconscious conscious. Resolve long
                standing pain and discomfort and connect deeply to yourself
                through the surprising impact of learning anatomy from the
                inside out without really trying. Experience what your body has
                to tell you as you experience moving the way your body wants and
                needs to move.
              </p>
              <p>
                For more information:{" "}
                <a
                  href="https://www.bodymindspiritanatomy.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  bodymindspiritanatomy.com
                </a>
              </p>
              <p>
                Who is Conscious Anatomy™ for? Manual and movement therapists
                and you if you want to
                <ul>
                  <li>
                    Increase your energy, health, vitality, inner calm and
                    balance
                  </li>
                  <li>
                    Process grief, anxiety, depression, aches and pains and
                    uplift your spirits
                  </li>
                  <li>
                    Cultivate tools to deeply listen to your body without
                    getting in your own way
                  </li>
                  <li>
                    Engage in blissful, calming movement flows that allow you to
                    customize to your body and optimize your healing
                  </li>
                  <li>
                    Experience anatomy from the inside out with the added
                    benefit of absorbing anatomy without really trying
                  </li>
                  <li>Feel refreshed, calm, enlightened and present</li>
                </ul>
              </p>
              <h2>Testimonials</h2>

              <ul>
                <li>
                  <strong>
                    Carol Breslin, Support Manager for the Developmentally
                    Disabled:{" "}
                  </strong>
                  Conscious Anatomy™ classes with Ann Watkins are excellent.
                  Whether I am using a signature flow for a brief refresher or
                  participating in a full class I always feel rejuvenated, more
                  flexible, and ready for my day.{" "}
                </li>
                <li>
                  <strong>Rosa Peurillo Yoga Teacher and Herbalist: </strong>
                  Conscious Anatomy™ has kept me balanced, in tune and energized
                  when I've been down and low energy.
                </li>
                <li>
                  {" "}
                  Sarah Pantolone Spanish teacher and massage therapist: I came
                  for the anatomy, I stayed for the somatic awareness and
                  energetic attunement.{" "}
                </li>
                <li>
                  <strong>
                    Susan Christini Math Teacher and Entrepreneur:{" "}
                  </strong>
                  My friend died and Conscious Anatomy™ really helped me get
                  through that . IF you're on the fence, I say take it; it is so
                  much more than you think. You won't be disappointed.{" "}
                </li>
                <li>
                  <strong>
                    Abby Valetta Life Coach, Yoga Teacher and Massage Therapist:{" "}
                  </strong>
                  The movement is so gentle, I am always amazed at how great I
                  feel after class. And the anatomy just soaked in; it was
                  really just an added benefit, a side effect if you will. This
                  course is well worth it.
                </li>
              </ul>
              <h3>Author</h3>
              <p>
                Hi, I'm Ann Watkins. I am passionate about bringing customized
                postural corrective pain reducing body work sessions and
                self-care movement classes and trainings to as many individuals
                as possible to improve the quality of life on the planet. More
                harmony in the world one group, one person at a time.
              </p>
            </div>
            <h1 style={boilerTitles}>Events</h1>
            <div style={boilerText}>
              <h4>Online Training</h4>
              <h4>Concious Anatomy: Level 1</h4>
              <h4>Conscious Anatomy Bones Planes and Directions</h4>
              {/* <p>COST: $297.00</p> */}
              <p>
                Concious Anatomy brings 8 Live easy on the body movement classes
                that decrease pain, increase range of motion, decrease anxiety,
                increase calm energy in the comfort of your own home over Zoom.
                You will need two snow angels worth of floor space and
                comfortable clothes.
              </p>

              <p>
                For Class Details:{" "}
                <a
                  href="https://www.bodymindspiritanatomy.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  bodymindspiritanatomy.com
                </a>
              </p>

              <p>
                For Questions Contact Us:{" "}
                <a href="tel:860-599-4533" target="_blank" rel="noreferrer">
                  860-599-4533
                </a>{" "}
                or{" "}
                <a
                  href="mailto:arwatkins54@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  arwatkins54@gmail.com
                </a>
              </p>

              <h2>Level One Training</h2>
              <ul>
                <li>
                  Ease into your body with blissful movement flows designed for
                  you to increase range of motion, decrease and eliminate pain,
                  increase your energy, decrease anxiety, discover joie de vivre
                  and absorb the anatomy without really trying.
                </li>
                <li>
                  Especially for all of my private practice clients (learn what
                  you can do on your own to support what we do on the table) and
                  manual and movement therapists{" "}
                </li>
                <li>
                  For specific class titles and a description of each class go
                  to bodymindspiritanatomy.com and scroll down
                </li>
                <li>
                  Access all 8 classes on line following completion of the
                  course for life of the course
                </li>
                <li>
                  Anatomy demos on line prior to each class as a preparation for
                  each class over Zoom
                </li>
                <li>
                  Enjoy supplemental videos as often and whenever desired along
                  with other supplemental materials to enhance your learning
                </li>
              </ul>
              <p>
                ​No experience necessary. Wear comfortable clothes you can move
                in and be able to get on and off the floor.
              </p>

              <h2>Testimonials</h2>

              <ul>
                <li>
                  <strong>Megan P: </strong>I came for the Anatomy, I stayed for
                  the Somatic Awareness and Energetic Attunement.{" "}
                </li>

                <li>
                  <strong>Nathan G: </strong>
                  After repeated athletic injuries over the years. Concious
                  Anatomy has helped me connect to and understand my body
                  movements. I've been injury free ever since I started the
                  courses.{" "}
                </li>
                <li>
                  <strong>Sandra B: </strong>I always feel better after class.
                  And I love that when I'm at the hospital for my Dad or a
                  client, I understand the conversations I hear between the
                  doctors and nurses.
                </li>
              </ul>
            </div>

            <h1 style={boilerTitles}>A Moment for Yourself</h1>
            <div style={boilerText}>
              <h4>
                Welcome to the pre- sign up page for A Moment for Yourself Mini
                Chakra Series/Conscious Anatomy™{" "}
              </h4>
              <p>
                If you want to sign up for the Mini Chakra series click here for
                the proper web form to receive that series in your inbox.
              </p>{" "}
              {/* This needs to be a form that sends a email to my moms inbox or set up as a kajabi auto mailer */}
              {/* http://www.newharmonymassage.com/minischakraseriessignup.html */}
              <p>
                What will you get when you sign up for the Mini Chakra Series?
                The first day you'll receive Chakra Basics. A couple of days
                later in the week you will receive 20 Different Ways to Work
                with that Chakra. If you signed up as a member of the Conscious
                Anatomy™ site for free resources or a training, you should find
                yourself at the welcome page with directions for access. If that
                somehow doesn't happen you can always email me at
                ann@newharmonymassage.com or call 860-599-4533. I'll get back to
                you asap if you don't catch me live.
              </p>
              <p>
                {" "}
                For Trainings, once you make your payment, you will have access
                to the Conscious Anatomy™ website which will give you access to
                each class as promised over the eight or nine week period as
                specified and you will have continued access for an additional 2
                months after completion. At that point you can become a member
                of the site and have ongoing access. You always have access to
                the free resources on the site. Enjoy!
              </p>
              <p>
                <p>
                  If you are interested in specific health tips and other
                  information from archived installments of{" "}
                  <a
                    href="/aMomentForYourself"
                    target="_blank"
                    rel="noreferrer"
                  >
                    A Moment for Yourself
                  </a>
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Blog;
