// import { Button } from "bootstrap";
import * as React from "react";
import "./routes.css";
import Chakra from "../aMomentForYourselfArchive/09-26-2016-working-with-1st-chakra.jpg";
import ChakraBasics from "../aMomentForYourselfArchive/09-19-2016-1st-chakra-basics.jpg";
import Maintenance from "../aMomentForYourselfArchive/09-12-2016-maintenance.jpg";
import JamesBroughton from "../aMomentForYourselfArchive/09-05-2016-poem-james-broughton.jpg";
import Moment from "../aMomentForYourselfArchive/08-29-216-a-moment.png";
import LifeFlies from "../aMomentForYourselfArchive/08-22-2016-life-flies-by.png";
import Empowerment from "../aMomentForYourselfArchive/08-08-2016-empowerment.png";
import StandingUpStraight from "../aMomentForYourselfArchive/08-01-2016-standing-up-straight.png";
import AngelKiss from "../aMomentForYourselfArchive/08-2016-angel-kiss.png";
import NonMovingMeditation from "../aMomentForYourselfArchive/07-25-2016-non-moving-meditation.png";
import MeditationTips from "../aMomentForYourselfArchive/07-11-2016-more-tips-on-meditation.png";
import MeditationSleep from "../aMomentForYourselfArchive/07-04-2016-15-min-meditation-one-hour-sleep.png";
import CarpalTunnel from "../aMomentForYourselfArchive/06-2016-what-i-miss-carpal-tunnel.png";
import SomaticDharma from "../aMomentForYourselfArchive/11-2015-common-ground-intestines-somatic-dharma.png";
import BeautyMistake from "../aMomentForYourselfArchive/10-2015-beauty-mistake-foundations-consciousness.png";
import DarknessIntoLight from "../aMomentForYourselfArchive/09-2015-darkness-into-light-wanting-strong-shoulders.png";
import CalmNervousSystem from "../aMomentForYourselfArchive/07-2015-calm-nervous-system.png";
import WalkingMeditation from "../aMomentForYourselfArchive/07-18-2015-walking-meditation.png";
import Perfectionism from "../aMomentForYourselfArchive/04-2015-perfectionism-pelvic-floor.png";
import MuscleSpasms from "../aMomentForYourselfArchive/03-2015-sleeping-peeing-muscle-spasms.png";
import SleepBetter from "../aMomentForYourselfArchive/02-2015-sleep-bettter.png";
import BrainGym from "../aMomentForYourselfArchive/12-2014-brain-gym-calm-nervous-system.png";
import VisceralNervousSystem from "../aMomentForYourselfArchive/11-2014-visceral-calm-nervous-system.png";
import KumNyeYoga from "../aMomentForYourselfArchive/09-2014-calming-breath-from-kum-nye-yoga.png";

const aboutUsTitles = {
  color: "#B46000",
  padding: ".5rem",
  marginLeft: "1rem",
  paddingTop: "1rem",
};
const aboutBackground = {
  backgroundColor: "#D0C88E",
};
const aboutContentBackground = {
  backgroundColor: "#E9E4D2",
};
const aboutText = {
  padding: ".5rem",
  marginLeft: "1rem",
};
const maintextlink = {
  textDecoration: "none",
};
function aMomentForYourself() {
  return (
    <>
      <div style={aboutBackground}>
        <div className="container">
          <div style={aboutContentBackground}>
            <h1 style={aboutUsTitles}>A Moment For Yourself Archives</h1>
            <h5 style={aboutText}>
              <strong>2016</strong> <br />
              <small>Read the 2016 A Moment For Yourself below:</small>
            </h5>
            <div style={aboutText}>
              <a
                href={Chakra}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                09 26 2016 Working with the 1st Chakra
              </a>
              <br />
              <a
                href={ChakraBasics}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                09 19 2016 1st Chakra Basics
              </a>
              <br />
              <a
                href={Maintenance}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                09 12 2016 Maintenance
              </a>
              <br />
              <a
                href={JamesBroughton}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                09 05 2016 Poem James Broughton
              </a>
              <br />
              <a
                href={Moment}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                08 29 2016 A Moment
              </a>
              <br />
              <a
                href={LifeFlies}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                08 22 2016 Life Flies By
              </a>{" "}
              <br />
              <a
                href={Empowerment}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                08 08 2016 Empowerment
              </a>{" "}
              <br />
              <a
                href={StandingUpStraight}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                08 01 2016 Standing up Straight
              </a>{" "}
              <br />
              <a
                href={AngelKiss}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                08 2016 Angel Kiss
              </a>
              <br />
              <a
                href={NonMovingMeditation}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                07 25 2016 Non moving Meditation
              </a>{" "}
              <br />
              <a
                href={MeditationTips}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                07 11 2016 More tips on Meditation
              </a>{" "}
              <br />
              <a
                href={MeditationSleep}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                07 04 2016 15 min Meditation one Hour Sleep
              </a>{" "}
              <br />
              <a
                href={CarpalTunnel}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                06 2016 What I miss Carpal Tunnel
              </a>
            </div>
            <h5 style={aboutText}>
              <strong>2015</strong> <br />
              <small>Read the 2015 A Moment For Yourself below:</small>
            </h5>
            <div style={aboutText}>
              <a
                href={SomaticDharma}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                11 2015 Common Ground Intestines Somatic Dharma{" "}
              </a>
              <br />
              <a
                href={BeautyMistake}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                10 2015 Beauty Mistake Foundations Consciousness
              </a>
              <br />
              <a
                href={DarknessIntoLight}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                09 2015 Darkness into Light Wanting Strong Shoulders
              </a>
              <br />
              <a
                href={CalmNervousSystem}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                07 2015 Calm Nervous System
              </a>
              <br />
              <a
                href={WalkingMeditation}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                07 18 2015 Walking Meditation
              </a>{" "}
              <br />
              <a
                href={Perfectionism}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                04 2015 Perfectionism Pelvic Floor
              </a>
              <br />
              <a
                href={MuscleSpasms}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                03 2015 Sleeping Peeing Muscle Spasms{" "}
              </a>
              <br />
              <a
                href={SleepBetter}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                02 2015 Sleep Better
              </a>
            </div>
            <h5 style={aboutText}>
              <strong>2014</strong> <br />
              <small>Read the 2014 A Moment For Yourself below:</small>
            </h5>
            <div style={aboutText}>
              <a
                href={BrainGym}
                rel="noreferrer"
                target="_blank"
                style={maintextlink}
              >
                12 2014 Brain Gym Calm Nervous System
              </a>
              <br />
              <a
                href={VisceralNervousSystem}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                11 2014 Visceral Calm Nervous System
              </a>
              <br />
              <a
                href={KumNyeYoga}
                target="_blank"
                rel="noreferrer"
                style={maintextlink}
              >
                09 2014 Calming Breath from Kum Nye Yoga
              </a>
              <br />
              <p className="mt-2">
                We're collecting testimonials to post on the website. Please
                Send them to:{" "}
                <a
                  href="mailto: arwatkins54@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  arwatkins54@gmail.com
                </a>
              </p>
              <p>
                Ann Watkins is owner of New Harmony Therapeutic Massage and
                Somatic Bodywork. She has been in private practice since 1988
                and has studied numerous forms of bodywork with her primary
                focus being postural correction and chronic pain. She also holds
                a Masters in Dance Movement Therapy. Copyright Jan. 2012
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default aMomentForYourself;
