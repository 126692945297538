// import { Button } from "bootstrap";
import * as React from "react";
import "./routes.css";
import newsAnnPhoto from "../img/newsAnnPhoto.jpg";

const aboutBackground = {
  backgroundColor: "#D0C88E",
};
const aboutContentBackground = {
  backgroundColor: "#E9E4D2",
};
const aboutText = {
  padding: ".5rem",
  marginLeft: "1rem",
};
const aboutUsTitles = {
  color: "#B46000",
  padding: ".5rem",
  marginLeft: "1rem",
  paddingTop: "1rem",
};
const therapiesPad = {
  padding: "1.5rem",
};
const contactAnn = {
  // height: "35vh",
  width: "25vw",
  borderRadius: "5px",
  boxShadow: "5px 5px 8px 5px rgba(0, 0, 0, 0.449)",
  margin: "1rem",
};

function Resources() {
  return (
    <>
      <div style={aboutBackground}>
        <div className="container">
          <div style={aboutContentBackground}>
            <h1 style={aboutUsTitles}>Resources</h1>

            <h5 style={aboutText}>
              <strong>A Moment for Yourself</strong> <br />
              <a href="/aMomentForYourself" target="_blank" rel="noreferrer">
                <small>Click Here:</small>
              </a>
            </h5>
            <h5 style={aboutText}>
              To receive information about bodywork and movement classes,
              courses and freebies please sign up at bodymindspiritanatomy.com.
              Questions, comments, suggestions, testimonials, thoughts can be
              sent to arwatkins54@gmail.com. Please note that any newharmony
              email addresses are not currently supported by this platform. For
              now, please only use the gmail address. Thanks!{" "}
              <a
                href="mailto:arwatkins54@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <small>arwatkins54@gmail.com</small>
              </a>
            </h5>

            <div className="container">
              <div
                className="accordion"
                id="accordionExample"
                style={therapiesPad}
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <strong>Spring & Summer 2010</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div style={aboutText}>
                        <h5>
                          <strong>Spring & Summer 2010 Newsletter</strong>{" "}
                          <br />
                          <p>
                            E-news will come out once a season. We've made
                            discoveries and have ideas for feeling better and
                            being healthier and more vibrant; we'll be sending
                            those out as well.
                          </p>
                        </h5>
                        <h5>
                          <strong>
                            Part II Spring & Summer 2010 Correcting Rotations
                            For NMR’ers
                          </strong>{" "}
                          <br />
                          <p>
                            I’ve been working on rotations for years, especially
                            the last six or seven, and mostly they take time to
                            work out. Slight rotations can be the crankiest in
                            fact, but all rotations involve muscles of rotation
                            and the spine.
                          </p>
                          <p>
                            Rotations in the torso – be they in the hips, the
                            mid-back, or the shoulders/cervicals are complicated
                            and, especially in the case of scoliosis, can take
                            numbers of sessions to work out. However, there are
                            certain muscles that are invariably implicated and
                            others that are implicated after getting the primary
                            ones initially worked out. The initial muscles that
                            you’ll be working with are: serratus anterior, low
                            trapezius, psoas and gluteus maximus. These four
                            muscles are the beginning and may blow each other
                            out in Triads – please see my DVD or ask someone who
                            has from Alive and Well!. (I’d love to teach a class
                            on Triads. If you’re interested, please send name,
                            address and phone number to arwatkins54@gmail.com
                            and we’ll be in touch){" "}
                            <a
                              href="mailto:arwatkins54@gmail.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <small>arwatkins54@gmail.com</small>
                            </a>
                          </p>
                          <p>
                            A typical triad pattern in hip rotations will
                            involve three of the following: glut maximus,
                            anterior serratus, low trap, and psoas and may
                            switch off more than one of the other muscles. The
                            ones that make up the Triad depend on the rotation.
                            At a certain point, after a couple of corrections,
                            the pattern can switch to the mirroring set of
                            muscles being switched off. In other words, rather
                            than left glut, right low trap switching off right
                            psoas, rt glut, left low trap, is switching off left
                            psoas. Usually it will switch back to the primary
                            offending pattern after a correction or two.
                          </p>
                          <p>
                            The person on the table may note that the same
                            muscle patterns are being tested over and over and
                            it appears they aren’t correcting. In fact, we are
                            re-correcting them each time they get switched off
                            due to a change in alignment brought about by other
                            corrections we’re doing in between. What is
                            happening is the following: the same muscle pattern
                            is being tested over and over again to ensure that
                            it is switching on as the alignment of the skeleton
                            changes in response to other patterns being
                            corrected in between. The fact that we have to keep
                            correcting a particular pattern is a good thing
                            because with each new alignment attained we correct
                            the offending pattern, which means that as the
                            person moves through myriad normal alignment changes
                            in daily life the pattern will still switch on as
                            needed.
                          </p>
                          <p>
                            With each pattern we correct there is the
                            possibility that the original offending pattern has
                            switched off again. We want to make sure it is
                            switching on as the person’s alignment changes.
                          </p>
                          <p>
                            A telltale sign that an offending pattern has
                            switched off again is the following: after
                            accomplishing better alignment the body reverts to
                            misalignment after a correction. It is imperative to
                            go back to the implicated pattern and make sure it
                            hasn’t switched off. This stands for any chronic
                            problem you’re trying to correct.
                          </p>
                          <p>
                            It is imperative to go back and check the pattern(s)
                            that chronically shut(s) down as the alignment
                            incrementally changes as per the red flags –
                            reversion to misalignment, recurrence of pain gotten
                            rid of earlier, a muscle seizing up that was relaxed
                            a moment ago. As we move through life our alignment
                            changes minute to minute. We want all our muscle
                            patterns to work no matter what alignment we are in;
                            we don’t want a pattern to only work in a particular
                            alignment. That is exactly what pulls us back into
                            chronic misalignments and pain.
                          </p>
                          <p>
                            Just this morning I was working on a client who has
                            trouble with her left shoulder, which is much built
                            up between her scalpula and spine causing a shoulder
                            rotation toward the ceiling when she is prone. One
                            of the problems was the pattern between the
                            supraspinatus and the low trap. First the supra
                            switched off the low trap, which we corrected. Then
                            the triad of rhomboid to supra switched off the low
                            trap, which we corrected. We did some triad work
                            with both rhomboids and anterior serratus’ and she
                            started getting discomfort in the upper shoulder. I
                            checked the supra to the low trap and the low trap
                            was getting switched off again, so we corrected it
                            again. Then we worked with the serratus, the
                            rhomboids, the rectus femorous’, and various other
                            spinal problem patterns. When checking her cervicals
                            to her low back and rectus femorous she got a spasm
                            in the shoulder area. Back we went to see if the
                            supra was once again switching off the low trap – it
                            was and once again we corrected it. Then we
                            corrected the triad we had uncovered involving the
                            ser, rhomb., and rectus femorous. With each
                            correction we could feel the supra releasing at a
                            deeper and deeper level. When you work this way you
                            have much better staying power with each session and
                            you get into the deep layers that can’t be reached
                            initially.
                          </p>
                        </h5>
                        <h5>
                          <strong>Tip of the Season</strong> <br />
                          <p>
                            Check the diaphragm and jolts when a muscle turns
                            off at odd times or doesn’t correct right away.
                          </p>
                          <p>
                            Good luck, any questions feel free to give me a
                            call. Ann
                          </p>
                        </h5>
                        <h5>
                          <strong>We're Collecting Testimonials</strong> <br />
                          <p>
                            Send them to:{" "}
                            <a
                              href="mailto:arwatkins54@gmail.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              arwatkins54@gmail.com
                            </a>
                          </p>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <strong>Fall 2009</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div style={aboutText}>
                        <h5>
                          <strong>
                            Part I Summer/Fall 2009 Correcting Rotations
                          </strong>{" "}
                          <br />
                          <p>
                            Rotations are more often than not systemic. The
                            muscles involved tend to be on the diagonal from one
                            another until you get into deeper layers and then
                            they can switch to a line of force and the opposite
                            diagonal for a correction or two.
                          </p>
                          <p>
                            When I work with rotations, I usually start with
                            four muscles: the low traps, the psoas’, the
                            serratus’ anterior, and the gluteus maximus’ to see
                            what is strong and what is switching off. I
                            definitely check triads. See my DVD Reactive Triads
                            or ask someone who has.
                          </p>
                          <p>
                            Muscles must appropriately switch on to improve
                            alignment- notice, when you activate a muscle,
                            whether alignment improves or worsens. Work with the
                            muscles toward improvement.
                          </p>
                          <p>
                            For example: imagine in the prone position one glut
                            is rotated toward the ceiling. Having the person
                            press down on one serratus anterior brings better
                            alignment, the other worse. Testing the low
                            trapezius in the prone position, one brings better
                            alignment, the other worse. Activating the opposite
                            psoas to the raised glut should bring better
                            alignment. The three muscles that bring better
                            alignment to the glut maximus in question, are the
                            muscles you will be working with- at least at first,
                            in the pattern of reactive pairs and triads. There
                            will most likely come a time within this process
                            where you will find that the opposite muscle pattern
                            is suddenly switching off, for a correction or two,
                            and then reverts back to the original offending
                            pattern - as your work brings you closer and closer
                            to deep muscles close to the skeleton.
                          </p>
                          <p>
                            After working with the original problem muscle
                            patterns adequately, sometimes a lot of work,
                            sometimes none or not much, you will move to the
                            skeletal erectors in conjunction with these four
                            muscles and the low abdominals. Start with checking
                            the four above indicated muscles against the
                            cervicals, then the thoracics, then lumbar,
                            stimulate the sacrum. Correct as you go, working out
                            those small spinal muscles that are switching off
                            the muscles you’re working with.
                          </p>
                          <p>
                            After making all corrections necessary, go back to
                            what didn’t originally switch off even thought you
                            expected it to when you initially activated the
                            various erectors or the initial patterns and see if
                            they switch off now. This is important. The ones
                            that show up the second time around and that you
                            then correct are the very ones that otherwise draw
                            the body back into misalignment after the person
                            gets off your table.
                          </p>
                          <p>
                            Don’t get caught in thinking “ I already corrected
                            that.” or “I already tested that and it worked, even
                            though I thought it wasn’t going to.” The patterns
                            that chronically switch off in response to changes
                            in body posture are the very ones that you need to
                            chronically check back with to make sure they are
                            still switching on to make sure they will switch on
                            no matter what the alignment.
                          </p>
                          <p>
                            Good luck, any questions feel free to give me a
                            call. Ann
                          </p>
                        </h5>
                        <h5>
                          <strong>Tip of the season:</strong> <br />
                          <p>
                            Recheck key patterns throughout the session. Just
                            because you corrected a key pattern once during a
                            session doesn’t mean other work you’ve done during
                            the session to improve skeletal alignment isn’t
                            effecting it – notably exposing new muscle fibers
                            that aren’t activating and, hence, that muscle that
                            was working a moment ago may be switching off again
                            in that pattern you corrected earlier. You need to
                            recheck, especially when the skeletal alignment
                            reverts in response to something you just corrected
                            and if a muscle that was relaxed is now tight again.
                            This creates real staying power from a session.
                          </p>
                        </h5>
                        <h5>
                          <strong>We're Collecting Testimonials</strong> <br />
                          <p>
                            Send them to:{" "}
                            <a
                              href="mailto:arwatkins54@gmail.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              arwatkins54@gmail.com
                            </a>
                          </p>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <strong>Spring 2009</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div style={aboutText}>
                        <h5>
                          <strong>Spring 2009 Newsletter</strong> <br />
                          <p>
                            We've made discoveries and have ideas for feeling
                            better and being healthier and more vibrant; we'll
                            be sending those out as well.
                          </p>
                        </h5>
                        <h5>
                          <strong>Important Nutritional Information</strong>{" "}
                          <br />
                          <p>
                            I went to Kripalu Yoga Center this past February and
                            March and learned some really important nutritional
                            information from John Bagnelo, PhD – Kripalu’s
                            nutritionist. And from Marc Grossman, a behavioral
                            optometrist and acupuncturist with a practice in
                            Rye, NY. Check out Marc Grossman’s website{" "}
                            <a
                              href="https://www.naturaleyecare.com/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              naturaleyecare.com
                            </a>{" "}
                            for more detailed information on wholistic eye care
                            – he’s a great guy too. And check out Daction
                            website{" "}
                            <a
                              href="https://www.grassrootshealth.net/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              grassrootshealth.net
                            </a>{" "}
                            for more information on D
                          </p>
                          <p>
                            According to John, at this latitude from Oct. to
                            March the sun is not intense enough to provide us
                            with enough vitamin D to sustain us. We need to be
                            taking 5000-10,000 IUs daily during that time of the
                            year. D creates a natural antibiotic in our system,
                            hence because the sun is not intense enough for us
                            to create that antibiotic in our systems, winter
                            time is flu season.
                          </p>
                          <p>
                            D must be present to properly process calcium in our
                            system. It is also a hormone regulator and can
                            become even more crucial during those change of life
                            times.
                          </p>
                          <p>
                            Vitamin A, which we typically get a lot of in our
                            American diet, must be present to process D
                            properly. Word to the wise, A is an antioxidant and
                            there are studies that show that taking any
                            antioxidant in supplemental form not made from whole
                            foods can have a neutral to damaging effect.
                            Apparently, antioxidants in such extracted form seat
                            themselves on the DNA kill switch of a cell and keep
                            the cell from dying. So we don’t get renewed healthy
                            replacements, we get proliferation of unhealthy
                            cells.
                          </p>
                          <p>
                            D can be toxic, so it is smart to know what your D
                            levels are. Check out Daction on the web for an
                            inexpensive way to monitor your vitamin D and also
                            check out Mercola.com for lots of helpful
                            information regarding D and other health
                            information.
                          </p>
                          <p>
                            Receiving this information about antioxidants and
                            vitamin D was a saving grace for me. I was getting
                            headaches once a month for four days straight. At
                            one point I’d started taking a whole foods
                            supplement of greens and that seemed to help. During
                            the summer I thought I’d conquered the problem. Then
                            winter rolled around and the headaches returned. I
                            went to Kripalu to de-stress and conquer those
                            headaches and learned about D. Voila, problem
                            solved- well, almost. It turned out I needed
                            antibiotics for Lyme Disease as well. I’ve also
                            taken to eating lots of sweet potatoes and yams, and
                            homemade applesauce. John is a proponent of the
                            Paleolithic diet- these food sources give you
                            proverbial bang for your nutritional buck when it
                            comes to intestinal health and getting enough fiber
                            to keep your colon healthy, so are really worth
                            including regularly in your diet.
                          </p>
                          <p>
                            If you’re interested in a class on colon health
                            please call me for information at 860-599-4533.
                          </p>
                          <p>
                            Since returning from Marc Grossman’s workshop at
                            Kripalu on eye nutrition, I’ve started to add kale
                            and orange and red peppers to my diet. Those contain
                            primary nutrients needed for eye health – lutein,
                            taurine, zeaxanthin. Nope, I’d never heard of them
                            either.
                          </p>
                          <p>
                            I love Kripalu, I thought I knew plenty about
                            nutrition. These guys have kept me up on nutrition I
                            didn’t know I didn’t know. Check out Marc Grossman’s
                            website{" "}
                            <a
                              href="https://www.naturaleyecare.com/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              naturaleyecare.com
                            </a>{" "}
                            for more detailed information on wholistic eye care
                            – he’s a great guy too. And check out Daction
                            website{" "}
                            <a
                              href="https://www.grassrootshealth.net/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              grassrootshealth.net
                            </a>{" "}
                            for more information on vitamin D and an inexpensive
                            way to get your own levels tested and monitored.
                          </p>
                        </h5>
                        <h5>
                          <strong>E-News Tip of the season:</strong> <br />
                          <p>
                            for all of you NMR folks out there: When a muscle
                            doesn’t let go as much as it should, find other
                            muscles it is also switching off, then go back and
                            release the muscle for all of them at once- keep
                            checking possible muscles until it really releases a
                            good amount.
                          </p>
                        </h5>
                        <h5>
                          <strong>We're Collecting Testimonials</strong> <br />
                          <p>
                            Send them to:{" "}
                            <a
                              href="mailto:arwatkins54@gmail.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              arwatkins54@gmail.com
                            </a>
                          </p>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <strong>Winter 2008</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div style={aboutText}>
                        <h5>
                          <strong>Winter 2008 Newsletter</strong> <br />
                          <p>
                            We've made discoveries and have ideas for feeling
                            better and being healthier and more vibrant; we'll
                            be sending those out as well.
                          </p>
                        </h5>
                        <h5>
                          <strong>Getting Ready for Winter: Breathe</strong>{" "}
                          <br />
                          <p>
                            Winter winds are starting to blow; the solstice is
                            creeping up on us. Just as we were getting used to
                            longer days and warmer weather, the days are
                            suddenly shorter.
                          </p>
                          <p>
                            Winter brings to my mind cozy fires, blankets of
                            snow, hibernation.
                          </p>
                          <p>
                            Hibernation recalls animals in caves sleeping, the
                            rise and fall of the breath.
                          </p>
                          <p>
                            Breathing is so important. When we can’t catch our
                            breath, or our breath catches so can’t fully breath,
                            it is quite unpleasant.
                          </p>
                          <p>
                            Try this if your breath catches: allow the front of
                            your chest to gently and organically respond as you
                            depress your scapula (your shoulder blades) down
                            toward your waist and hold them there, keeping your
                            shoulders relaxed and away from your ears. Do this a
                            few times to fully contract those muscles, then
                            relax without slumping. Relax and allow your rib
                            cage to expand under your armpits; allow your breath
                            to cause your chest to rise and allow your ribs in
                            the back to separate and expand.
                          </p>
                          <p>
                            If this fails, do some comfortable stretching that
                            your body is asking for and try again. If that
                            fails, try doing some quick vertical stimulation and
                            massage along your sternum (where your ribs come
                            together in the front of your body) and thump your
                            thyroid (just under your throat). Relax and breathe;
                            try depressing your scapula a few more times,
                            exploring how to release the tension holding back
                            your breath. Take as full and deep a breath as you
                            can. Finally, try grabbing your waist and squeezing
                            it, letting your scapula move down your back. This
                            will gently force your chest open and can relax the
                            implicated back muscles (usually the posterior
                            inferior serratus).
                          </p>
                          <p>
                            Using these little techniques can be a great way to
                            relax just before falling asleep at night- our mini
                            hibernation.
                          </p>
                          <p>Winter, here we come!</p>
                        </h5>
                        <h5>
                          <strong>E-News Tip of the season:</strong> <br />
                          <p>
                            Eat more raw fruits and vegetables even though it’s
                            winter. We evolved eating far more raw food than we
                            do these days. Raw foods have enzymes in them that
                            supplement the enzymes we produce in our gut. We
                            don’t actually produce enough enzymes for proper
                            digestion. Therefore, we need to eat raw in order to
                            properly digest. Eat raw with every meal to keep our
                            digestive system and your colon healthy.
                          </p>
                          <p>
                            Stay warm!
                            <br />
                            Ann
                          </p>
                        </h5>
                        <h5>
                          <strong>We're Collecting Testimonials</strong> <br />
                          <p>
                            Send them to:{" "}
                            <a
                              href="mailto:arwatkins54@gmail.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              arwatkins54@gmail.com
                            </a>
                          </p>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <strong>Summer 2008</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div style={aboutText}>
                        <h5>
                          <strong>Summer 2008 Newsletter</strong> <br />
                          <p>
                            We've made discoveries and have ideas for feeling
                            better and being healthier and more vibrant; we'll
                            be sending those out as well.
                          </p>
                        </h5>
                        <h5>
                          <strong>
                            Welcome current clients, previous clients,
                            potentially new clients, interested parties and
                            professionals.{" "}
                          </strong>{" "}
                          <br />
                          <p>
                            There are a few topics we’ll cover today. The first
                            regards an exciting{" "}
                            <a href="/store" target="_blank" rel="noreferrer">
                              new 2 disc DVD
                            </a>{" "}
                            of techniques developed by Ann Watkins that is
                            oriented toward professionals in practice and
                            instructors (perhaps someone like yourself or
                            someone you know). Reactive Triads gives the viewer
                            a comprehensive introduction to concepts not seen
                            elsewhere in the world of bodywork. At the end there
                            are directives for further study and information.
                            Ann Watkins, through continued study and application
                            in an evolving practice, has put together what
                            constitutes a Chronic Pain Breakthrough! She and
                            Randy Shepherd integrate Reactive Triads with other
                            techniques to bring you lasting bodywork. Our
                            mission is to improve the quality of life on the
                            planet, one person at a time. Our massage therapy is
                            a terrific adjunct to many other effective therapies
                            for the client or the practitioner. Check out our
                            Store for further information and reviews on the
                            DVD.
                          </p>
                        </h5>
                        <h5>
                          <strong>
                            Also announcing the completion of our Green Studio
                          </strong>{" "}
                          <br />
                          <p>
                            Beautiful airy cathedral ceilings with radiant floor
                            heat and hot water powered by our solar collectors.
                            This keeps costs down and lightens our footprint on
                            the planet. See pictures at Green Studio.
                          </p>
                        </h5>
                        <h5>
                          <strong>E-News Tip of the season:</strong> <br />
                          <p>
                            Eat more raw fruits and vegetables even though it’s
                            winter. We evolved eating far more raw food than we
                            do these days. Raw foods have enzymes in them that
                            supplement the enzymes we produce in our gut. We
                            don’t actually produce enough enzymes for proper
                            digestion. Therefore, we need to eat raw in order to
                            properly digest. Eat raw with every meal to keep our
                            digestive system and your colon healthy.
                          </p>
                          <p>
                            Stay warm!
                            <br />
                            Ann
                          </p>
                        </h5>
                        <h5>
                          <strong>We're Collecting Testimonials</strong> <br />
                          <p>
                            Send them to:{" "}
                            <a
                              href="mailto:arwatkins54@gmail.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              arwatkins54@gmail.com
                            </a>
                          </p>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h5 style={aboutText}>
              <strong>Press & News</strong> <br />
            </h5>
            <div style={aboutText}>
              <h5>
                <strong>
                  Reprogramming Muscles: New Harmony delivers the cutting edge
                  in holistic healing
                </strong>{" "}
              </h5>

              <br />
              <p>The Stonington Times</p>
              <p>By Russ Morey, Times Staff Writer</p>
            </div>

            <div style={aboutText}>
              <div style={{ textAlign: "center" }}>
                <img src={newsAnnPhoto} style={contactAnn} alt="newsAnnPhoto" />
              </div>

              <h5>
                <strong>Neuro Muscular Reprogramming Practitioners</strong>{" "}
                <br />
                <a
                  href="https://www.alivewell.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <small>Institute of Conscious BodyWork-Alive & Well</small>
                </a>
              </h5>
              <h5>
                {/* <strong>NeuroKinetic Therapy</strong> <br /> */}
                <a
                  href="https://neurokinetictherapy.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <small>NeuroKinetic Therapy</small>
                </a>
              </h5>
              <h5>
                <strong>Music</strong> <br />
                <a
                  href="https://shantalamusic.com/benjy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <small>Benjy Wertheimer</small>
                </a>
              </h5>
            </div>

            <div style={aboutText}>
              <p>
                With light pouring through the skylights of the high vaulted
                ceilings of their rooms, massage therapists Ann Watkins and
                Randy Shepherd bring a whole new kind of therapy to eastern
                Connecticut.
              </p>
              <p>
                "Neuromuscular reprogramming is based on the idea that when
                muscles overcompensate, other muscles are not doing their job.
                The solution is not just massaging muscles that hurt, but
                finding the root of the problem and alleviating the
                overcompensation. That’s when healing really occurs,” explained
                Watkins.
              </p>
              <p>
                Watkins and Shepherd both studied at Alive and Well!, a world
                renowned, cutting edge school located in Corte Modera, Ca. After
                both became certified and moved back to the area in the late
                1990’s, they helped expand their business, New Harmony, now
                based in a spacious, new addition to their home at 13 River
                Crest Drive in Pawcatuck.
              </p>
              <p>
                While specializing in neuromuscular reprogramming, Watkins and
                Shepherd also offer many traditional massage techniques,
                including deep-tissue, shiatsu and acupressure. Watkins is also
                starting a new program called “moving from the inside out,” a
                project that will put her master’s degree in dance movement
                therapy to work. The couple will be offering massage classes,
                and Watkins will soon release an instructional DVD
                “Neuromuscular Triads.” For more information, call (860)
                599-4533.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Resources;
