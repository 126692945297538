import Button from "react-bootstrap/Button";
import * as React from "react";

import "./routes.css";
import annMovement from "../img/annMovement.jpg";
import ReactiveTriadsLogo from "../img/greenReactiveTriadsBodyworkLogo.png";
// import annsRoomLg from "../img/annsroom_lg.jpg";
// import staffsRoom from "../img/staffsroom.jpg";
// import staffsRoomLg from "../img/staffsroom_lg.jpg";
// import reactiveTriadsDvd from "../img/reactiveTriadsDvd.jpg";

const greenStudioTitles = {
  color: "#B46000",
  padding: ".5rem",
  marginLeft: "1rem",
  paddingTop: "1rem",
};

const greenStudioBackground = {
  backgroundColor: "#D0C88E",
};
const greenStudioContentBackground = {
  backgroundColor: "#E9E4D2",
};
const aboutText = {
  padding: ".5rem",
  marginLeft: "1rem",
};
const contactAnn = {
  width: "25vw",
  borderRadius: "5px",
  boxShadow: "5px 5px 8px 5px rgba(0, 0, 0, 0.449)",
  marginLeft: "1rem",
};

function Classes() {
  return (
    <>
      <div style={greenStudioBackground}>
        <div className="container">
          <div style={greenStudioContentBackground}>
            <h1 style={greenStudioTitles}>Conscious Anatomy™ Movement</h1>
            <div style={aboutText}>
              <h5>
                <small>
                  Conscious Anatomy™ Movement is for everyone who wants to learn
                  anatomy through blissful yogic movement flows that focuses on
                  the integration of body, mind, and spirit. Conscious Anatomy™
                  Movement is especially for manual and movement therapists who
                  really want to understand how living, moving bodies work from
                  the inside out. And, as one student put it “learn the
                  anatomical terms and concepts without really trying”. Ann
                  Watkins, Masters in Dance Movement Therapy, certified in
                  Expressive Arts Therapy, nationally licensed in massage and
                  bodywork, owner of New Harmony Therapeutic Massage and Somatic
                  Body Work since 1988, is passionate about movement, increasing
                  knowledge, healing herself and offering healing to others,
                  believing this will bring more harmony to the whole world.
                  Classes available anytime on line at your own pace and live or
                  in person a few times a year.
                </small>
              </h5>
              <p>
                Ann Watkins, Masters in Dance Movement Therapy, certified in
                Expressive Arts Therapy, nationally licensed in massage and
                bodywork, owner of New Harmony Therapeutic Massage and Somatic
                Body Work since 1988, is passionate about movement, increasing
                knowledge, healing herself and offering healing to others,
                believing those things bring more harmony to the whole world.
                She is a Peace Spider spinning her fabric of contemplation for
                all to participate in. Please join her if you can.
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <img src={annMovement} style={contactAnn} alt="ann movement" />
            </div>

            <div className="center" style={aboutText}>
              <p>
                <strong>
                  {" "}
                  Changing The Awareness Of The World One Class, One Training At
                  A Time: <br />
                </strong>{" "}
                In Conscious Anatomy™ we feel better after class than when we
                started. We become healthier and more vital while we learn the
                language of anatomy and absorb anatomical terms and concepts
                through movement. Simultaneously, as we move, we relieve muscle
                tension with somatic awareness; with energetic attunement, we
                learn how to make our energy body more tangible so it works for
                us in our daily lives and careers; and, we open our brains to
                become sponges for learning anatomy. Experience heightened
                energetic awareness, decreased tension, track down inner peace,
                become healthier and more graceful in body mind and spirit and
                discover ease in learning anatomy and function while healing
                your body at the same time. Infuse greater vitality into your
                life. You might even discover yourself embarking on a new career
                path.
              </p>
              <p>
                <strong>Conscious Anatomy™:</strong>
                <br />
                Movement is a four level course series. It starts with Bones
                Planes and Directions in Level 1 and moves into Muscles and
                Attachments in the next 3 Levels. For more information, dates
                and times please see bodymindspiritanatomy.com.
              </p>
              <p>
                <strong>C.A. Level I: </strong>
                <br />
                Bones Planes and Directions 1 eight week module
              </p>
              <p>
                <strong>C.A. Level II: </strong>
                <br />
                Muscles and Attachments 3 eight week modules where you will
                discover how muscles fire sequentially with one another as we
                move and bliss out in the movement at the same time.
              </p>
              <p>
                <strong>C.A. Level III: </strong>
                <br />
                Deepen Somatic Awareness and Energetic Attunement and have
                anatomy at your fingertips.
              </p>
              <p>
                Interested in body work or perhaps you are already a massage or
                manual therapist? Conscious Anatomy™ builds into Reactive
                Triads™ Bodywork: a chronic pain breakthrough, engaging three
                body systems in a form of bodywork that is tremendously
                effective for reducing/eliminating chronic and acute muscle
                pain, and is postural corrective. It teaches muscles how to
                relax. For more information see{" "}
                <a href="https://www.bodymindspiritanatomy.com/">
                  bodymindspiritanatomy.com
                </a>
              </p>
              <p>
                {" "}
                Have questions? <br />
                Phone{" "}
                <a href="tel:(860) 599-4533" target="_blank" rel="noreferrer">
                  (860) 599-4533
                </a>
                <br />
                E-mail Ann at{" "}
                <a
                  href="mailto:arwatkins54@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  arwatkins54@gmail.com
                </a>
                <br />
                <a
                  href="https://www.bodymindspiritanatomy.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button>Purchase Courses</Button>
                </a>
              </p>
              <p>
                <strong>Reactive Triads™ Level I: </strong> Bodywork: Muscle,
                Triads, & Jolts
              </p>
              <p>
                <strong>Reactive Triads™ Level II: </strong> Bodywork: Muscle,
                Lymph, Triads & Mixed Triads
              </p>
            </div>

            <div className="text-center">
              <iframe
                // width="560"
                // height="315"
                src="https://www.youtube.com/embed/NYKKulvYOeU?si=tnUBC5UtZViitycd"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div>
              <h1 style={greenStudioTitles}>Reactive Triads™ Body Work</h1>
              <div style={aboutText}>
                {/* link img to therapies reactive traids accodian definition */}
                <img src={ReactiveTriadsLogo} alt="Reactive Triads"></img>
                {/* <p>
                  {" "}
                  For classes on the West Coast visit{" "}
                  <a
                    href="https://www.alivewell.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    alivewell.com
                  </a>{" "}
                  (our Alma Mater- in body work) or{" "}
                  <a
                    href="https://neurokinetictherapy.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NeuroKinetic Therapy
                  </a>{" "}
                  (our other teacher) please refer to their websites.
                </p> */}

                <div style={{ textAlign: "center" }}>
                  <p>
                    Purchase DVD of Ann Watkins' original Reactive Triads™ Body
                    Work:{" "}
                    <a
                      href="https://www.bodymindspiritanatomy.com/offers/tEMaQJqu"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button>Go to Offers</Button>
                    </a>
                  </p>
                </div>

                <p>
                  Classes are offered in Anatomy and Muscle Testing as
                  prerequisites for Reactive Triads classes.{" "}
                </p>
                <p>
                  Call <a href="tel:(860) 599-4533">(860) 599-4533</a> <br />
                  E-mail Ann at{" "}
                  <a
                    href="mailto:arwatkins54@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    arwatkins54@gmail.com
                  </a>
                </p>
              </div>
            </div>

            <div>
              <h1 style={greenStudioTitles}>Supervision and Mentoring</h1>
              <div style={aboutText}>
                <p>
                  I am available to help you build greater confidence and a more
                  effective practice through supervision and mentoring. This can
                  happen in person, over the phone or over video conferencing
                  (Zoom). Prepare your questions before each call and I will
                  provide you with helpful guidance. I’ve had a thriving
                  practice since 1988 and can answer questions on how to build a
                  practice from the ground up with guidance on how to more
                  effectively treat your clients. This is especially useful if
                  you have taken courses with me and are applying the work in
                  sessions with your clients.
                </p>
                <p>
                  <strong>Phone Mentoring: </strong>
                  <br />
                  $40.00 per 1/2 hour. Free consultation to see if this is right
                  for you.
                </p>
                <p>
                  Phone <a href="tel:(860) 599-4533">(860) 599-4533</a> <br />
                  E-mail Ann at{" "}
                  <a
                    href="mailto:arwatkins54@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    arwatkins54@gmail.com
                  </a>
                </p>

                <div>
                  <p>
                    <strong>Check out my DVD Reactive Triads™: </strong>
                    <br />A Breakthrough in Chronic Pain Relief, published in
                    2006 – solving chronic muscle pain problems and achyness
                    that used to have no remedy. This DVD documents my original
                    body work that involves the muscle system, the lymphatic
                    system and the joint system. For more information, click
                    below:{" "}
                    <a
                      href="https://www.bodymindspiritanatomy.com/offers/tEMaQJqu"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button>Go to Offers</Button>
                    </a>
                  </p>
                </div>

                <div style={{ textAlign: "center" }}>
                  <p>
                    {/* #mbms (Movement for Body Mind Spirit) */}
                    <a href="/testimonials" target="_blank" rel="noreferrer">
                      <Button>Testimonials</Button>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Classes;
